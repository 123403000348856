import { combineReducers } from "redux";
import courseReducer from "./courseReducer";
import testimonialReducer from "./testimonialReducer";
import jobReducer from "./jobReducer";
import paymentReducer from "./paymentReducer";
import mentorReducer from "./mentorReducer";
import loginReducer from "./loginReducer";
import profileReducer from "./profileReducer";
import batchReducer from "./batchReducer";
import masterclassReducer from "./masterclassReducer";
import { blogReducer } from "./blogReducer";

export const reducers = combineReducers({
  courseData: courseReducer,
  testimonialData: testimonialReducer,
  jobData: jobReducer,
  paymentData: paymentReducer,
  mentorData: mentorReducer,
  login: loginReducer,
  profileData: profileReducer,
  batchData: batchReducer,
  masterclassData: masterclassReducer,
  blogData: blogReducer,
});
