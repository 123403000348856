import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-phone-input-2/lib/bootstrap.css";
import "react-calendar/dist/Calendar.css";
import 'video-react/dist/video-react.css'; // import css
import 'react-quill/dist/quill.snow.css';
import "react-circular-progressbar/dist/styles.css";
// import "animate.css/animate.min.css";
import"./asset/css/calender.css"
import "./asset/css/style.css";
import "./asset/css/feature.css";
import "./asset/css/ourteam.css";
import "./asset/css/responsive.css";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { GetRoutes, LazyLoadingWrapper } from "./action/customFn";
import RoutesArr from "./routes";
import { Suspense } from "react";
import Loader from "./component/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAllTestimonials } from "./action/testimonialAction";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import { asynCProfileData } from "./action/profileAction";
import { setLogin, setLogout } from "./action/loginAction";
import { jwtDecode } from "jwt-decode";
import "./asset/css/NewStyle.css";
import "./asset/css/NewResponsive.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { setAllMentors } from "./action/mentorAction";
// ..
AOS.init();

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(asyncGetAllTestimonials());
    // window.addEventListener('contextmenu', function (e) {
    //   e.preventDefault();
    // });
  }, []);

  // useEffect(() => {
  //   setInterval(() => {
  //     const images = document.querySelectorAll("img");
  //     images.forEach((img) => {
  //       img.setAttribute("loading", "lazy");
  //     });
  //   },1000);
  // }, [pathname]);
  // Recursive function to add loading="lazy" to all elements

  const isLoggedIn = useSelector((state) => state.login);

  const checkTokenExp = () => {
    const token = localStorage.getItem("oeson_token");
    if (token) {
      if (jwtDecode(token).exp < Date.now() / 1000) {
        localStorage.removeItem("oeson_token");
        localStorage.removeItem("oeson_role");
        navigate("/");
        dispatch(setLogout());
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("oeson_token")) {
      dispatch(setLogin());
    } else {
      dispatch(setLogout());
    }
  }, []);

  useEffect(() => {
    checkTokenExp();
    dispatch(setAllMentors([]));
  }, [pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(asynCProfileData());
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <HelmetProvider>
        <Toaster position="top-center" reverseOrder={false} />
        <Suspense fallback={<Loader />}>
          <Routes>{GetRoutes(RoutesArr)}</Routes>
        </Suspense>
      </HelmetProvider>
    </div>
  );
}

export default App;
